import { I18n } from "aws-amplify"

export const languages: {[key:string]: string} = {
    'en': 'English',
    'es': 'Español',
    'de': 'Deutsch',
    'fr': 'Français',
    'it': 'Italiano',
    'nl': 'Nederlands',
}

export const advertiserSignUp: {[key:string]: string} = {
    'en': 'https://www.webgains.com/public/en/start-a-program/',
    'es': 'https://www.webgains.com/public/es/empezar-un-programa/',
    'de': 'https://www.webgains.com/public/de/partnerprogramm-starten/',
    'fr': 'https://www.webgains.com/public/fr/demarrer-un-programme/',
    'it': 'https://www.webgains.com/public/en/start-a-program/',
    'nl': 'https://www.webgains.com/public/en/start-a-program/',
}

export const LANGUAGE_KEY = 'wg-language'

export const getBrowserLanguage = () => {
    let language = (navigator.languages && navigator.languages[0]) || navigator.language
    language = language.split('-').shift() || 'en'
    return languages[language] !== undefined ? language : 'en'
}

export const setupInternationalization = (language = getLanguage()) => {
    const translations = {[language]: require(`../locales/${language}/translation.json`)}
    I18n.putVocabularies(translations)
    I18n.setLanguage(language)
    return I18n
}

export const getLanguage = (): string => {
    return localStorage.getItem(LANGUAGE_KEY)??getBrowserLanguage()
}

export const storeLanguage = (language: string): void => {
    return localStorage.setItem(LANGUAGE_KEY, language)
}

